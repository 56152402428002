<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false; setResumptionDate()"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">reason for leave</label>
          <select class="form-control col-md-4" v-model="request.reason" required>
            <option value="" disabled>-- Select a reson for this LOA --</option>
            <option v-for="reason in reasons" :value="reason.value">{{ reason.description }}</option>
          </select>
          <div class="col-md-4 invalid-feedback">
            Please select a valid reason from the list.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">start date</label>
          <input type="date" id="startDate" class="form-control col-md-2" v-model="request.startDate" required :min="minimumStartDate" />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            {{ startDateError || 'please select a valid date.' }}'
          </div>
        </div>
        <div v-if="showStartDatePolicy">
          <label class="col-md-3"></label>
          <button type="button" class="popover btn btn-lg btn-danger offset-3" data-toggle="popover">Click to view enforced policies</button>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">end date</label>
          <input type="date" id="endDate" class="form-control col-md-2" v-model="request.endDate" required :min="request.startDate" :max="maximumEndDate" />
          <span class="mr-1"> {{ request.endDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            please select a valid date. (Read the policy for more details)
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">number of days</label>
          <input type="number" class="form-control col-md-1" v-model="request.duration" readonly />
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">resumption date</label>
          <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly />
          <span> {{ request.resumptionDate | toLongDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">contact phone #</label>
          <input type="text" class="form-control col-md-2" v-model="request.contactPhoneNumber" required />
          <div class="col-md-4 invalid-feedback">
            please enter a valid phone number.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">contact address</label>
          <textarea class="form-control col-md-4" rows="3" v-model="request.contactAddress" required></textarea>
          <div class="col-md-4 invalid-feedback">
            please enter a valid address.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">remarks</label>
          <textarea class="form-control col-md-4" rows="4" v-model="request.remarks"></textarea>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"></label>
          <div class="col-md-7">
            <vue-dropzone v-if="isEditable" id="dropzone" v-bind:class="{ disabled: isFormDisabled }" ref="supportingDocumentsDropzone" :options="dropzoneOptions" required></vue-dropzone>
            <p class="mt-1" v-if="availableDocuments && availableDocuments.length">
              <div class="badge badge-secondary m-2 p-2" v-for="supportingDocument in availableDocuments" :key="supportingDocument.id">
                <span style="cursor: pointer" @click="downloadDocument(request.id, supportingDocument.id,supportingDocument.originalDocumentName)">{{ supportingDocument.originalDocumentName }}</span><span v-if="isEditable" class="ml-3" style="cursor: pointer;" @click="removeDocument(supportingDocument.id)">&#x2715</span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  .popover {
    position: relative !important;
  }

  .dropzone.disabled {
    pointer-events: none;
    cursor: default;
  }
</style>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'

  import { isLeaveStartDateLessThanCurrentDate, getLeaveDates, fromServerDate } from '@/utils/date'
  import Enums from '@/utils/enums'
  import isSameDay from 'date-fns/isSameDay'
  import addYears from 'date-fns/addYears'
  import subDays from 'date-fns/subDays'
  import addMonths from 'date-fns/addMonths'
  import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
  import max from 'date-fns/max'


  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'
  import LOARequestMixin from '@/mixins/leaveRequest/loaRequest'

  export default {
    name: 'leave-of-absencee-entry',
    mixins: [LeaveRequestEntryFormMixin, LOARequestMixin],
    components: {
      StartDateWarningModal,
      vueDropzone: vue2Dropzone
    },
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      sortedPreviousStudyOrLOA: {
        type: Array,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      },
      isEditable: {
        type: Boolean,
        required: false,
        default: true
      },
      additionalData: {
        type: Object,
        required: false,
      }
    },
    data: () => ({
      reasons: Enums.LeaveOfAbsenceReasons,
      showLeaveStartDateWarning: false,
      startDateError: '',
      dropzoneOptions: {
        paramName: "files",
        url: '/',
        maxFiles: 10,
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        timeout: 0,
        dictDefaultMessage: 'Drop supporting documents (including application letter)  here or click to upload.',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
      },
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings
      }),
      showStartDatePolicy() {
        return this.appSettings.leaveOfAbsence.enforceRequiredYearsOfContinousEmployment || this.appSettings.leaveOfAbsence.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest || this.appSettings.leaveOfAbsence.enforceMinimumNumberOfYearsAfterForeignServiceForNonPersonalRequest
      },
      minimumStartDate() {
        let date = new Date()
        if (!this.request.id) {
          if (this.appSettings.leaveOfAbsence.enforceNoticeRule) {
          //  date = addMonths(Date.parse(date), this.appSettings.leaveOfAbsence.minimumMonthsForNotice)
          }
          if (this.appSettings.leaveOfAbsence.enforceRequiredYearsOfContinousEmployment) {
            const dateAfterContinousYearsOfService = addYears(Date.parse(this.employeeInfo.employmentDate), this.appSettings.leaveOfAbsence.requiredYearsOfContinousEmployment)
            date = max([date, dateAfterContinousYearsOfService])
          }
          if (this.appSettings.leaveOfAbsence.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest && this.sortedPreviousStudyOrLOA.length) {
            let sortedPreviousStudyOrLOA = [...this.sortedPreviousStudyOrLOA]
            if (this.request.id) {
              sortedPreviousStudyOrLOA = sortedPreviousStudyOrLOA.filter((request) => request.id != this.$route.params.id)
            }
            if (sortedPreviousStudyOrLOA.length) {
              const lastLeave = sortedPreviousStudyOrLOA[sortedPreviousStudyOrLOA.length - 1]
              var xYearsAfterLastSLorLOA = addYears(Date.parse(lastLeave.actualResumptionDate), this.appSettings.leaveOfAbsence.minimumNumberOfYearsAfterPreviousLOAsOrSLForRequest)
              date = max([date, xYearsAfterLastSLorLOA])
            }
          }
        }
        else {
          date = Date.parse(this.request.startDate)
        }
        return fromServerDate(date)
      },
      maximumEndDate() {
        if (this.appSettings.leaveOfAbsence.enforceReturnToNMAAfterForeignServiceForNonPersonalRequestLastingMoreThanXMonths && this.employeeInfo.isOnForeignService && this.request.reason != Enums.LeaveOfAbsenceReasons.Personal.value && this.request.startDate) {
          return fromServerDate(subDays(addMonths(Date.parse(this.request.startDate), this.appSettings.leaveOfAbsence.maximumNumberOfMonthsForLOAForEmployeesOnForeignSeviceForNonPersonalRequests), 1))
        }
        if (this.appSettings.leaveOfAbsence.enforceMaximumMonthsForNonPersonalRequests && this.request.reason != Enums.LeaveOfAbsenceReasons.Personal.value && this.request.startDate) {
          return fromServerDate(addMonths(Date.parse(this.request.startDate), this.appSettings.leaveOfAbsence.maximumNumberOfMonthsForNonPersonalRequests))
        }
        return ''
      }
    },
    watch: {
      //'request.startDate'() {
      //  const self = this
      //  if (self.sortedPreviousStudyOrLOA.length) {
      //    const startDateElement = document.querySelector('#startDate');
      //    //const leave = self.sortedPreviousStudyOrLOA[0]
      //    const leave = self.sortedPreviousStudyOrLOA[self.sortedPreviousStudyOrLOA.length - 1]
      //    if (!leave.actualResumptionDate || compareAsc(Date.parse(self.request.startDate), addYears(Date.parse(leave.actualResumptionDate), 1)) == -1) {
      //      self.startDateError = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToLeaveStartDateLessThanAYearAfterPreviousRequests, this.request.leaveType)
      //      //startDateElement.setCustomValidity(self.startDateError)
      //    }
      //    else {
      //      self.startDateError = ''
      //      startDateElement.setCustomValidity(self.startDateError)
      //    }
      //  }
      //}
    },
    methods: {
      rejectStartDate() {
        this.request.startDate = ''
        this.request.endDate = ''
        this.request.resumptionDate = ''
        this.showLeaveStartDateWarning = false
      },
      setResumptionDate() {
        if (this.request.startDate && this.request.endDate) {
          this.request.duration = differenceInCalendarDays(Date.parse(this.request.endDate), Date.parse(this.request.startDate)) + 1
          var dates = getLeaveDates(this.request.startDate, this.request.duration, true, this.employeeInfo.isFieldEmployee, this.holidayDates)
          this.request.resumptionDate = dates.resumptionDate
        }
      }
    },
    created() {
      this.ignoreDurationWatch = true
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');
      const endDateElement = document.querySelector('#endDate');

      startDateElement.addEventListener('change', (event) => {
        if (!self.employeeInfo.isFieldEmployee) {
          let selectedDate = new Date(event.target.value)
          var day = new Date(selectedDate).getUTCDay()
          let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
          if ([6, 0].includes(day) || startsOnHoliday) {
            event.preventDefault();
            alert('Weekends / public holidays not allowed');
            event.target.value = '';
            self.request.startDate = null
            self.request.holidays = null
            self.request.endDate = null
            self.request.resumptionDate = null
            startDateElement.click()
            return
          }
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setResumptionDate()
            }
          }
        })
      });
      endDateElement.addEventListener('change', (event) => {
        if (!self.employeeInfo.isFieldEmployee) {
          let selectedDate = new Date(event.target.value)
          var day = new Date(selectedDate).getUTCDay()
          let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
          if ([6, 0].includes(day) || startsOnHoliday) {
            event.preventDefault();
            alert('Weekends / public holidays not allowed');
            event.target.value = '';
            self.request.holidays = null
            self.request.endDate = null
            self.request.resumptionDate = null
            endDateElement.click()
            return
          }
        }
        Vue.nextTick(() => {
          self.setResumptionDate()
        })
      });

      if (this.showStartDatePolicy) {
        let popverContent = '<ul>'
        if (this.appSettings.leaveOfAbsence.enforceRequiredYearsOfContinousEmployment) {
          popverContent += `<li>Employee must have been in CNL’s continuous employment for ${this.appSettings.leaveOfAbsence.requiredYearsOfContinousEmployment} or more years.</li>`
        }
        if (this.appSettings.leaveOfAbsence.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest) {
          popverContent += `<li>An employee who took a study leave or LOA previously will not be eligible for another LOA until they have returned to work for a minimum of ${this.appSettings.leaveOfAbsence.minimumNumberOfYearsAfterPreviousLOAsOrSLForRequest} year.</li>`
        }
        if (this.appSettings.leaveOfAbsence.enforceNoApplicationFromEmployeeOnForeignService) {
          popverContent += `<li>Employese on foreign service should contact HRBP for more guidance</li>`
        }
        else if (this.appSettings.leaveOfAbsence.enforceReturnToNMAAfterForeignServiceForNonPersonalRequestLastingMoreThanXMonths) {
          popverContent += `<li>Employees on foreign training assignments will complete their assignments and return to Nigeria before they can obtain approval for LOA that is not personal in nature and that spans more than three months. If LOA is personal in nature, return to Nigeria before commencement of LOA is not required.</li>`
        }
        if (this.appSettings.leaveOfAbsence.enforceMaximumNumberOfYearsForNonPersonalRequest) {
          popverContent += `<li>Where LOA is required for reasons other than personal, the duration of such leave shall be for a maximum of ${this.appSettings.leaveOfAbsence.maximumNumberOfYearsForNonPersonalRequest} years. However, employee is required to renew the approval annually with the Director, Human Resources & Medical through his/her divisional manager or director</li>`
        }
        popverContent += '</ul>'
        popverContent += "<br />"
        popverContent += '<a  target=”_blank” href="https://d2-hou-hr.dm.chevron.net/D2/servlet/DispatchDownload?_docbase=HR_PROD&_locale=en&event_name=d2_view&version=CURRENT&id=0902e981801c0b61">View policy'
        $(".popover").popover({
          trigger: 'focus',
          title: '<h4 class="custom-title"><i class="fa fa-info-circle"></i>Leave of absence policies enforced by eleave</h3>',
          content: popverContent,
          html: true
        });
      }
    }
  }
</script>
