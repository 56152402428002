<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false; setResumptionDate()"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">institution</label>
          <input type="text" class="form-control col-md-4" v-model="request.institution" required />
          <div class="col-md-4 invalid-feedback">
            Please enter a valid school for study.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">program</label>
          <select class="form-control col-md-4" v-model="request.program" required>
            <option value="" disabled>-- Select a program --</option>
            <option v-for="program in studyLeavePrograms" :value="program">{{ program.description }}</option>
          </select>
          <div class="col-md-4 invalid-feedback">
            Please select a valid program from the list.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">course of study</label>
          <input type="text" class="form-control col-md-4" v-model="request.courseOfStudy" required />
          <div class="col-md-4 invalid-feedback">
            Please enter a valid school for study.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">start date</label>
          <input type="date" id="startDate" class="form-control col-md-2" v-model="request.startDate" required :min="minimumStartDate" />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            {{ startDateError || 'please select a valid date.' }}'
          </div>
        </div>
        <div v-if="showStartDatePolicy">
          <label class="col-md-3"></label>
          <button type="button" class="popover btn btn-lg btn-danger offset-3" data-toggle="popover">Click to view enforced policies</button>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">end date</label>
          <input type="date" id="endDate" class="form-control col-md-2" v-model="request.endDate" required :min="request.startDate" :max="maximumEndDate" />
          <span> {{ request.endDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            please select a valid date. (Cannot be before {{ request.startDate | toLongDateString }})
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">number of days</label>
          <input type="number" class="form-control col-md-1" v-model="request.duration" readonly />
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">resumption date</label>
          <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly />
          <span> {{ request.resumptionDate | toLongDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">contact phone #</label>
          <input type="text" class="form-control col-md-2" v-model="request.contactPhoneNumber" required />
          <div class="col-md-4 invalid-feedback">
            please enter a valid phone number.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">contact address</label>
          <textarea class="form-control col-md-4" rows="3" v-model="request.contactAddress" required></textarea>
          <div class="col-md-4 invalid-feedback">
            please enter a valid address.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">remarks</label>
          <textarea class="form-control col-md-4" rows="4" v-model="request.remarks"></textarea>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"></label>
          <div class="col-md-7">
            <vue-dropzone v-if="isEditable" id="dropzone" v-bind:class="{ disabled: isFormDisabled }" ref="supportingDocumentsDropzone" :options="dropzoneOptions" required></vue-dropzone>
            <p class="mt-1" v-if="availableDocuments && availableDocuments.length">
              <div class="badge badge-secondary m-2 p-2" v-for="supportingDocument in availableDocuments" :key="supportingDocument.id">
                <span style="cursor: pointer" @click="downloadDocument(request.id, supportingDocument.id, supportingDocument.originalDocumentName)">{{ supportingDocument.originalDocumentName }}</span><span v-if="isEditable" class="ml-3" style="cursor: pointer;" @click="removeDocument(supportingDocument.id)">&#x2715</span>
                <!--<a :href="'javascript(' + request.id + ',' + supportingDocument.id + ',' +  supportingDocument.originalDocumentName + ')'">{{ supportingDocument.originalDocumentName }}</a><span v-if="isEditable" class="ml-3" style="cursor: pointer;" @click="removeDocument(supportingDocument.id)">&#x2715</span>-->
              </div>
            </p>
          </div>
        </div>

      </div>
      </fieldset>
  </div>
</template>

<style scoped lang="scss">
  .popover {
    position: relative !important;
  }

  .dropzone.disabled {
    pointer-events: none;
    cursor: default;
  }
</style>

<script>

  import Vue from 'vue'
  import { mapState, mapGetters } from 'vuex'
  import { isLeaveStartDateLessThanCurrentDate, getLeaveDates, fromServerDate, flattenHolidayDates } from '@/utils/date'
  import ErrorMessages from '@/utils/errors/messages'

  import Enums from '@/utils/enums'
  import isSameDay from 'date-fns/isSameDay'
  import addYears from 'date-fns/addYears'
  import addMonths from 'date-fns/addMonths'
  import subDays from 'date-fns/subDays'
  import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
  import compareAsc from 'date-fns/compareAsc'
  import max from 'date-fns/max'

  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'
  import LOARequestMixin from '@/mixins/leaveRequest/loaRequest'

  export default {
    name: 'study-leave-entry',
    mixins: [LeaveRequestEntryFormMixin, LOARequestMixin],
    components: {
      StartDateWarningModal,
      vueDropzone: vue2Dropzone
    },
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      sortedPreviousStudyOrLOA: {
        type: Array,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      },
      isEditable: {
        type: Boolean,
        required: false,
        default: true
      },
      additionalData: {
        type: Object,
        required: false,
      }
    },
    data: () => ({
      programs: ['HND', 'BSC', 'MSC', 'PHD', '*Others'],
      showLeaveStartDateWarning: false,
      startDateError: '',
      dropzoneOptions: {
        paramName: "files",
        url: '/',
        maxFiles: 10,
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        timeout: 0,
        dictDefaultMessage: 'Drop supporting documents (including application letter, admission letter and any other supporting document)  here or click to upload.',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        init: function () {
          this.on("error", function (file) { if (!file.accepted) this.removeFile(file); });
        }
      },
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings,
      //  holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      ...mapGetters([
        'studyLeavePrograms',
      ]),
      //holidayDates() {
      //  let holidays = []
      //  if (this.holidays && this.holidays.length) {
      //    this.holidays.forEach((holiday) => {
      //      const dates = flattenHolidayDates(fromServerDate(holiday.startDate), holiday.numberOfDays)
      //      dates.forEach((date) => {
      //        holidays.push(date)
      //      })
      //    })
      //    //  return this.holidays.map(holiday => new Date(Date.parse(holiday.startDate)))
      //  }
      //  return holidays
      //},
      showStartDatePolicy() {
        return this.isEditable && (this.appSettings.studyLeave.enforceRequiredYearsOfContinousEmployment || this.appSettings.studyLeave.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest || this.appSettings.studyLeave.enforceMinimumNumberOfYearsAfterForeignServiceForRequest)
      },
      minimumStartDate() {
        let date = new Date()

        if (this.appSettings.studyLeave.enforceNoticeRule) {
          //date = addMonths(this.request.id ? new Date(Date.parse(this.request.requestDate)) : Date.parse(date), this.appSettings.leaveOfAbsence.minimumMonthsForNotice)
          //if (this.request.id) {
          //  date = min([new Date(Date.parse(this.request.requestDate)), new Date(Date.parse(fromServerDate(date)))])
          //}
        }
        if (this.appSettings.studyLeave.enforceRequiredYearsOfContinousEmployment) {
          const dateAfterContinousYearsOfService = addYears(Date.parse(this.employeeInfo.employmentDate), this.appSettings.leaveOfAbsence.requiredYearsOfContinousEmployment)
          date = max([date, dateAfterContinousYearsOfService])
        }
        if (this.appSettings.studyLeave.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest && this.sortedPreviousStudyOrLOA.length) {
          const lastLeave = this.sortedPreviousStudyOrLOA[this.sortedPreviousStudyOrLOA.length - 1]
          var xYearsAfterLastSLorLOA = addYears(Date.parse(lastLeave.actualResumptionDate), this.appSettings.studyLeave.minimumNumberOfYearsAfterPreviousLOAsOrSLForRequest)
          if (date) {
            date = max([Date.parse(date), Date.parse(xYearsAfterLastSLorLOA)])
          }
          else {
            date = xYearsAfterLastSLorLOA
          }
        }
        if (this.appSettings.studyLeave.enforceMinimumNumberOfYearsAfterForeignServiceForRequest && this.additionalData.employeeLocationHistory.length > 1) {
          const previousLocation = this.additionalData.employeeLocationHistory[1]
          if (previousLocation.locationId == '029') {
            const currentLocation = this.additionalData.employeeLocationHistory[0]
            var xYearsAfterForeignService = addYears(Date.parse(currentLocation.effectiveDate), this.appSettings.studyLeave.minimumNumberOfYearsAfterForeignServiceForRequest)
            if (date) {
              date = max([Date.parse(date), Date.parse(xYearsAfterForeignService)])
            }
            else {
              date = xYearsAfterForeignService
            }
          }
        }

        return fromServerDate(date)
      },
      maximumEndDate() {
        if (this.appSettings.studyLeave.enforceMaximumNumberOfYearsForRequest && this.request.startDate) {
          return subDays(addYears(Date.parse(this.request.startDate), this.appSettings.studyLeave.maximumNumberOfYearsForRequest), 1)
        }
        return ''
      }
    },
    watch: {
      'request.startDate'() {
        const self = this
        if (self.sortedPreviousStudyOrLOA.length) {
          const startDateElement = document.querySelector('#startDate');
          //const leave = self.sortedPreviousStudyOrLOA[0]
          const leave = self.sortedPreviousStudyOrLOA[self.sortedPreviousStudyOrLOA.length - 1]
          if (!leave.actualResumptionDate || compareAsc(Date.parse(self.request.startDate), addYears(Date.parse(leave.actualResumptionDate), 1)) == -1) {
            self.startDateError = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToLeaveStartDateLessThanAYearAfterPreviousRequests, this.request.leaveType)
            //startDateElement.setCustomValidity(self.startDateError)
          }
          else {
            self.startDateError = ''
            startDateElement.setCustomValidity(self.startDateError)
          }
        }
      }
    },
    methods: {
      rejectStartDate() {
        this.request.startDate = ''
        this.request.endDate = ''
        this.request.resumptionDate = ''
        this.showLeaveStartDateWarning = false
      },
      setResumptionDate() {
        if (this.request.startDate && this.request.endDate) {
          this.request.duration = differenceInCalendarDays(Date.parse(this.request.endDate), Date.parse(this.request.startDate)) + 1
          var dates = getLeaveDates(this.request.startDate, this.request.duration, true, this.employeeInfo.isFieldEmployee, this.holidayDates)
          this.request.resumptionDate = dates.resumptionDate
        }
      }
    },
    created() {
      this.ignoreDurationWatch = true
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');
      const endDateElement = document.querySelector('#endDate');

      startDateElement.addEventListener('change', (event) => {
        if (!self.employeeInfo.isFieldEmployee) {
          let selectedDate = new Date(event.target.value)
          var day = new Date(selectedDate).getUTCDay()
          let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
          if ([6, 0].includes(day) || startsOnHoliday) {
            event.preventDefault();
            alert('Weekends / public holidays not allowed');
            event.target.value = '';
            self.request.startDate = null
            self.request.holidays = null
            self.request.endDate = null
            self.request.resumptionDate = null
            startDateElement.click()
            return
          }
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setResumptionDate()
            }
          }
        })
      });
      endDateElement.addEventListener('change', (event) => {
        if (!self.employeeInfo.isFieldEmployee) {
          let selectedDate = new Date(event.target.value)
          var day = new Date(selectedDate).getUTCDay()
          let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
          if ([6, 0].includes(day) || startsOnHoliday) {
            event.preventDefault();
            alert('Weekends / public holidays not allowed');
            event.target.value = '';
            self.request.holidays = null
            self.request.endDate = null
            self.request.resumptionDate = null
            endDateElement.click()
            return
          }
        }
        Vue.nextTick(() => {
          self.setResumptionDate()
        })
      });

      if (this.showStartDatePolicy) {
        let popverContent = '<ul>'
        if (this.appSettings.studyLeave.enforceRequiredYearsOfContinousEmployment) {
          popverContent += `<li>Employee must have been in CNL’s continuous employment for ${this.appSettings.studyLeave.requiredYearsOfContinousEmployment} or more years.</li>`
        }
        if (this.appSettings.studyLeave.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest) {
          popverContent += `<li>An employee who took a Study leave or Leave of absence previously, is not eligible for another leave until he/she has returned to work for a minimum of ${this.appSettings.studyLeave.minimumNumberOfYearsAfterPreviousLOAsOrSLForRequest} year.</li>`
        }
        if (this.appSettings.studyLeave.enforceMinimumNumberOfYearsAfterForeignServiceForRequest) {
          popverContent += `<li>Employees on foreign assignment or on a company sponsored educational program, are required to complete their assignments, return to Nigeria and work for a minimum period of ${this.appSettings.studyLeave.minimumNumberOfYearsAfterForeignServiceForRequest} year before they can obtain study leave.</li>`
        }
        if (this.appSettings.studyLeave.enforceMaximumNumberOfYearsForRequest) {
          popverContent += `<li>Maximum number of years is ${this.appSettings.studyLeave.maximumNumberOfYearsForRequest} years</li>`
        }
        popverContent += '</ul>'
        popverContent += "<br />"
        popverContent += '<a  target=”_blank” href="https://d2-hou-hr.dm.chevron.net/D2/servlet/DispatchDownload?_docbase=HR_PROD&_locale=en&event_name=d2_view&version=CURRENT&id=0902e981801c0b61">View policy'
        $(".popover").popover({
          trigger: 'focus',
          title: '<h4 class="custom-title"><i class="fa fa-info-circle"></i>Study leave policies enforced by eleave</h3>',
          content: popverContent,
          html: true
        });
      }
    }
  }
</script>
