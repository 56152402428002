<template>
  <div id="requestsPendingMeView">
    <div class="row">
      <div class="col">
        <h4>extend study leave / leave of absence</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-spinner :show="showSpinner"></app-spinner>
        <div class="text-left">
          <app-modal id="selectedRequestModal" v-if="showExtensionModal" :show="showExtensionModal" :static="true" size="large" title="extend request">
            <form id="extensionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isExtendingRequest">
                <div class="form-group row mt-4">
                  <span class="col-md-3">start date</span>
                  <span class="col-md-6">{{ request.startDate | toLongDateString }}</span>
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">new end date</label>
                  <input type="date" id="endDate" class="form-control col-md-2" v-model="request.endDate" required :min="request.endDate" :max="maximumEndDateForSelectedRequest" />
                  <span> {{ request.endDate | toLongDateString }}</span>
                  <div class="col-md-4 invalid-feedback">
                    please select a valid date. (Cannot be before {{ request.endDate | toLongDateString }})
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">number of days</label>
                  <input type="number" class="form-control col-md-1" v-model="request.duration" readonly />
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">resumption date</label>
                  <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly />
                  <span> {{ request.resumptionDate | toLongDateString }}</span>
                </div>
                <div class="form-group row mt-4">
                  <span class="col-md-3">remarks</span>
                  <textarea class="form-control col-md-6 p-2" rows="3" required>{{ request.remarks }}</textarea>
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3"></label>
                  <div class="col-md-7">
                    <vue2Dropzone id="dropzone" v-bind:class="{ disabled: isExtendingRequest }" ref="supportingDocumentsDropzone" :options="dropzoneOptions" required></vue2Dropzone>
                  </div>
                </div>
              </fieldset>
            </form>
            <template v-slot:footer>
              <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isExtendingRequest">Cancel</button>
              <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isExtendingRequest }" @click.prevent="onExtendingRequestConfirmed" v-bind:disabled="isExtendingRequest">Submit<span class="spinner"></span></button>
            </template>
          </app-modal>

        </div>
        <RequestList :requests="requests" v-if="requests.length" />
        <div class="font-weight-bold" v-else-if="doneLoading">
          There are currently no study leave / leave of absence request that can be extended
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<style lang="scss">

  #selectedRequestModal {
    height: 90%;
  }

  #selectedRequestModal .modal-body-footer-container {
    height: 90%;
    overflow-y: auto
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { CLOSE_MODAL_EVENT, SELECT_REQUEST_EVENT } from '@/utils/constants'
  import Events from '@/utils/events'
  import { toShortDateString, toLongDateString } from '@/filters'
  import { fromServerDate, flattenHolidayDates, getLeaveDates } from '@/utils/date'

  import addYears from 'date-fns/addYears'
  import subDays from 'date-fns/subDays'
  import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

  import { loaRequestService } from '@/services'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import AppSpinner from '@/components/ui/AppSpinner'
  import RequestList from '@/components/loa/RequestList'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'RequestsPendingMeView',
    components: {
      RequestList,
      AppAlert,
      AppModal,
      AppSpinner,
      vue2Dropzone
    },
    mixins: [GeneralMixin, AccessControlMixin, AlertMixin, ErrorMixin],
    data: () => ({
      request: {
        endDate: '',
        resumptionDate: '',
        duration: null,
        remarks: ''
      },
      selectedRequest: null,
      requests: [],
      selectedRequest: null,
      isExtendingRequest: false,
      requests: [],
      showSpinner: false,
      showExtensionModal: false,
      doneLoading: false,
      dropzoneOptions: {
        paramName: "files",
        url: '/',
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: false,
        timeout: 0,
        dictDefaultMessage: 'Drop supporting document for this extension',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        init: function () {
          this.on("error", function (file) { if (!file.accepted) this.removeFile(file); });
        }
      }

    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings,
        holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      minimumEndDateForSelectedRequest() {
        return this.selectedRequest.endDate
      },
      maximumEndDateForSelectedRequest() {
        if (this.appSettings.studyLeave.enforceMaximumNumberOfYearsForExtensionOfRequest) {
          return fromServerDate(subDays(addYears(Date.parse(this.selectedRequest.endDate), this.appSettings.studyLeave.maximumNumberOfYearsForExtensionOfRequest), 1))
        }
        return ''
      },
      holidayDates() {
        let holidays = []
        if (this.holidays && this.holidays.length) {
          this.holidays.forEach((holiday) => {
            const dates = flattenHolidayDates(fromServerDate(holiday.startDate), holiday.numberOfDays)
            dates.forEach((date) => {
              holidays.push(date)
            })
          })
          //  return this.holidays.map(holiday => new Date(Date.parse(holiday.startDate)))
        }
        return holidays
      },

    },
    watch: {
      'request.endDate'(newValue, oldValue) {
        if (newValue) {
          this.setResumptionDate()
        }
      }
    },
    filters: {
      toShortDateString,
      toLongDateString
    },
    methods: {
      resetData() {
        this.resetRequestData();
        this.requests.splice(0, this.requests.length)
      },
      resetRequestData() {
        this.request.startDate = ''
        this.request.endDate = ''
        this.request.resumptionDate = ''
        this.request.duration = null
        this.selectedRequest = null
      },
      setResumptionDate() {
        if (this.request.endDate) {
          this.request.duration = differenceInCalendarDays(Date.parse(this.request.endDate), Date.parse(this.request.startDate)) + 1
          var dates = getLeaveDates(this.request.startDate, this.request.duration, true, this.selectedRequest.employeeInfo.isFieldEmployee, this.holidayDates)
          this.request.resumptionDate = dates.resumptionDate
        }
      },
      fetchData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        loaRequestService.getUnclosedRequests().then((result) => {
          const requests = result.items
          if (requests.length) {
            requests.forEach(request => {
              request.employeeName = request.employeeInfo.fullName
              self.requests.push(request)
            })
          }
          this.doneLoading = true
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      selectedRequestChanged(request) {
        this.request.startDate = fromServerDate(request.startDate)
        this.request.endDate = fromServerDate(request.endDate)
        this.request.resumptionDate = fromServerDate(request.resumptionDate)
        this.request.duration = request.duration
        this.selectedRequest = request
        this.showExtensionModal = true
      },
      onExtendingRequestConfirmed() {
        const self = this
        let request = { ...self.request }
        const requestForm = document.getElementById('extensionForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
        }
        else {
          if (this.$refs.supportingDocumentsDropzone.getQueuedFiles().length < 1) {
            self.showErrorMessage(`You must upload a minimum of 1 supporting document for this extension`)
            return
          }
          request.files = []
          for (const file of this.$refs.supportingDocumentsDropzone.getQueuedFiles()) {
            request.files.push({
              name: 'files',
              content: file
            })
          }
          self.closeMessage()
          self.isExtendingRequest = true
          loaRequestService.extendRequest(self.selectedRequest.id, request).then((result) => {
            self.selectedRequest.duration = self.request.duration
            self.selectedRequest.endDate = self.request.endDate
            self.selectedRequest.resumptionDate = self.request.resumptionDate
            self.resetRequestData()
            self.isExtendingRequest = false
            self.showExtensionModal = false
          }).catch((error) => {
            self.showErrorMessage(error)
            self.isExtendingRequest = false
          })
        }
    },
    onDismissAction() {
      this.resetRequestData()
      this.showExtensionModal = false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.resetRequestData()
      vm.fetchData()
    })
  },
  mounted() {
    eventBus.$on(SELECT_REQUEST_EVENT, this.selectedRequestChanged)
    eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
  },
  beforeDestroy() {
    eventBus.$off(SELECT_REQUEST_EVENT)
    eventBus.$off(CLOSE_MODAL_EVENT)
  }
  }
</script>
