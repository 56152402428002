<template>
  <div :id="id">
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">employee no.</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employeeNo }}</span>
        </div>
      </div>
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">name</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.fullName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">NMA join date</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ (employeeInfo.sbuStartDate ?  employeeInfo.sbuStartDate : employeeInfo.employmentDate) | toShortDateString }}</span>
        </div>
      </div>
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">employee type</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employeeTypeName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">emp. date</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employmentDate | toShortDateString }}</span>
        </div>
      </div>
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">work schedule</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.workScheduleName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0" v-if="!showVacationYearSelect">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">vacation Year</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ vacationMaster.vacationYear }}</span>
        </div>
      </div>
      <div class="col-md-6 mr-0" v-else>
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">vacation year</label>
          <select class="form-control col-md-3 mr-4" v-model="vacationMaster.vacationYear" required>
            <option v-for="year in additionalData.vacationYears" :value="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">payroll country</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.payrollCountry }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  /*select.form-control:not([size]):not([multiple]) {
    height: 27px;
  }*/
</style>

<script>

  import { mapState } from 'vuex'

  import { toShortDateString } from '@/filters'
  import Enums from '@/utils/enums'

  export default {
    name: 'employee-info-display',
    props: {
      id: {
        id: String,
        required: false,
        default: 'employeeInfoDisplay'
      },
      displayMode: {
        id: Boolean,
        required: false,
        default: false
      },
      showVacationYearSelect: {
        id: Boolean,
        required: false,
        default: false
      },
      employeeInfo: {
        type: Object,
        required: true
      },
      vacationMaster: {
        type: Object,
        required: true
      },
      additionalData: {
        type: Object,
        required: false
      }
    },
    //data: () => ({
    //  Enums: Enums
    //}),
    filters: {
      toShortDateString
    },
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      })
    }
  }
</script>
