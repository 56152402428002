<script>

  import { vacationMasterService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import Enums from '@/utils/enums'

  export default {
    data: () => ({
      vacationMaster: {
        id: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unscheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        fieldVacationOption: '',
        version: null
      },
      showGenerateVacationButton: false,
      isGeneratingVacationData: false
    }),
    methods: {
      generateVacationData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'Generating vacation data')
        self.isGeneratingVacationData = true
        vacationMasterService.createVacationMasterFor({ employeeId: self.employeeInfo.employeeId, vacationYear: self.request.vacationYear || self.additionalData.vacationYear || self.vacationMaster.vacationYear }).then((result) => {
          self.setVacationData(result)
          self.showGenerateVacationButton = false
        }).catch((error) => {
          self.handleError(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.isGeneratingVacationData = false
        })
      },
      setVacationData(vacationMaster) {
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unscheduledVacationDays = this.additionalData.startingUscheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.fieldVacationOption = vacationMaster.fieldVacationOption
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        this.vacationMaster.buyOutDays = vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule_CashInLieu || this.employeeInfo.workScheduleId === Enums.WorkSchedule.WS28x28.value ? vacationMaster.unscheduleVacationDays : (vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule14_Days ? Math.min(vacationMaster.vacationDays + vacationMaster.travelDays - 14, vacationMaster.unscheduleVacationDays) : 0)
        this.vacationMaster.version = vacationMaster.version
      },
    }
  }

</script>
