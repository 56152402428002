<template>
  <div id="requestsPendingMeView">
    <div class="row">
      <div class="col">
        <h4>close out study leave / leave of absence</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-spinner :show="showSpinner"></app-spinner>
        <div class="text-left">
          <app-modal id="selectedRequestModal" v-if="showCloseModal" :show="showCloseModal" :static="true" size="large" title="close out request">
            <form id="closeForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isClosingRequest">
                <div class="form-group row mt-4">
                  <span class="col-md-3">start date</span>
                  <span class="col-md-6">{{ selectedRequest.startDate | toShortDateString }}</span>
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">end date</label>
                  <input type="date" id="endDate" class="form-control col-md-2" v-model="selectedRequest.endDate" readonly />
                  <span> {{ selectedRequest.endDate | toLongDateString }}</span>
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">number of days</label>
                  <input type="number" class="form-control col-md-1" v-model="selectedRequest.duration" readonly />
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">resumption date</label>
                  <input type="date" class="form-control col-md-2" v-model="selectedRequest.resumptionDate" readonly />
                  <span> {{ selectedRequest.resumptionDate | toLongDateString }}</span>
                </div>
                <hr />
                <div class="form-group row mt-4">
                  <label class="col-md-3">actual end date</label>
                  <input type="date" id="actualEndDate" class="form-control col-md-2" v-model="request.endDate" required :min="selectedRequest.startDate" :max="selectedRequest.endDate" />
                  <span> {{ request.endDate | toLongDateString }}</span>
                  <div class="col-md-4 invalid-feedback">
                    please select a valid date. (Cannot be after {{ request.endDate | toLongDateString }})
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">number of days</label>
                  <input type="number" class="form-control col-md-1" v-model="request.duration" readonly />
                </div>
                <div class="form-group row mt-4">
                  <label class="col-md-3">actual resumption date</label>
                  <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly>
                  <span> {{ request.resumptionDate | toLongDateString }}</span>
                </div>
                <div class="form-group row mt-4">
                  <span class="col-md-3">remarks</span>
                  <textarea class="form-control col-md-6 p-2" rows="3" required>{{ request.remarks }}</textarea>
                </div>
              </fieldset>
            </form>
            <template v-slot:footer>
              <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isClosingRequest">Cancel</button>
              <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isClosingRequest }" @click.prevent="onCloseRequestConfirmed" v-bind:disabled="isClosingRequest">Submit<span class="spinner"></span></button>
            </template>
          </app-modal>

        </div>
        <RequestList :requests="requests" v-if="requests.length" />
        <div class="font-weight-bold" v-else-if="doneLoading">
          There are currently no study leave / leave of absence request that can be closed out
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<style lang="scss">

  #selectedRequestModal {
    height: 90%;
  }

  #selectedRequestModal .modal-body-footer-container {
    height: 90%;
    overflow-y: auto
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { CLOSE_MODAL_EVENT, SELECT_REQUEST_EVENT } from '@/utils/constants'
  import Events from '@/utils/events'
  import { toShortDateString, toLongDateString } from '@/filters'
  import { fromServerDate, flattenHolidayDates, getLeaveDates } from '@/utils/date'

  import addYears from 'date-fns/addYears'
  import subDays from 'date-fns/subDays'
  import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

  import { loaRequestService } from '@/services'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import AppSpinner from '@/components/ui/AppSpinner'
  import RequestList from '@/components/loa/RequestList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'RequestsPendingMeView',
    components: {
      RequestList,
      AppAlert,
      AppModal,
      AppSpinner
    },
    mixins: [GeneralMixin, AccessControlMixin, AlertMixin, ErrorMixin],
    data: () => ({
      request: {
        endDate: '',
        resumptionDate: '',
        actualEndDate: '',
        actualResumptionDate: '',
        duration: null,
        remarks: ''
      },
      requests: [],
      selectedRequest: {
        id: null,
        endDate: '',
        resumptionDate: '',
        actualEndDate: '',
        actualResumptionDate: '',
        duration: null,
        remarks: '',
        employeeInfo: {
          isFieldEmployee: false
        }
      },
      isClosingRequest: false,
      requests: [],
      showSpinner: false,
      showCloseModal: false,
      doneLoading: false,
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings,
        holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      holidayDates() {
        let holidays = []
        if (this.holidays && this.holidays.length) {
          this.holidays.forEach((holiday) => {
            const dates = flattenHolidayDates(fromServerDate(holiday.startDate), holiday.numberOfDays)
            dates.forEach((date) => {
              holidays.push(date)
            })
          })
          //  return this.holidays.map(holiday => new Date(Date.parse(holiday.startDate)))
        }
        return holidays
      },
    },
    watch: {
      'request.endDate'(newValue, oldValue) {
        if (newValue) {
          this.setResumptionDate()
        }
      }
    },
    filters: {
      toShortDateString,
      toLongDateString
    },
    methods: {
      resetData() {
        this.resetRequestData();
        this.requests.splice(0, this.requests.length)
      },
      resetRequestData() {
        this.selectedRequest.id = null
        this.request.startDate = this.selectedRequest.startDate = ''
        this.request.endDate = this.selectedRequest.endDate = ''
        this.request.resumptionDate = this.selectedRequest.resumptionDate = ''
        this.request.duration = this.selectedRequest.duration = null
        this.selectedRequest.id = null
        this.selectedRequest.employeeInfo.isFieldEmployee = false
      },
      setResumptionDate() {
        if (this.request.endDate) {
          this.request.duration = differenceInCalendarDays(Date.parse(this.request.endDate), Date.parse(this.request.startDate)) + 1
          var dates = getLeaveDates(this.request.startDate, this.request.duration, true, this.selectedRequest.employeeInfo.isFieldEmployee, this.holidayDates)
          this.request.resumptionDate = dates.resumptionDate
        }
      },
      fetchData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        loaRequestService.getUnclosedRequests().then((result) => {
          const requests = result.items
          if (requests.length) {
            requests.forEach(request => {
              request.employeeName = request.employeeInfo.fullName
              self.requests.push(request)
            })
          }
          this.doneLoading = true
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      selectedRequestChanged(request) {
        this.selectedRequest.id = request.id
        this.request.startDate = request.startDate = this.selectedRequest.startDate = fromServerDate(request.startDate)
        this.request.endDate = request.endDate = this.selectedRequest.endDate = fromServerDate(request.endDate)
        this.request.resumptionDate = request.resumptionDate = this.selectedRequest.resumptionDate = fromServerDate(request.resumptionDate)
        this.request.duration = this.selectedRequest.duration = request.duration
        this.selectedRequest.employeeInfo.isFieldEmployee = request.isFieldEmployee
        this.showCloseModal = true
      },
      onCloseRequestConfirmed() {
        const self = this
        let request = { ...self.request }
        const requestForm = document.getElementById('closeForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
        }
        self.closeMessage()
        self.isClosingRequest = true
        loaRequestService.closeOutRequest(self.selectedRequest.id, request).then((result) => {
          self.requests = self.requests.filter(function (request, index, arr) {
            return request.id != self.selectedRequest.id;
          });
          self.resetRequestData()
          self.isClosingRequest = false
          self.showCloseModal = false
        }).catch((error) => {
          self.showErrorMessage(error)
          self.isClosingRequest = false
        })
      },
      onDismissAction() {
        this.resetRequestData()
        this.showCloseModal = false
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.resetRequestData()
        vm.fetchData()
      })
    },
    mounted() {
      eventBus.$on(SELECT_REQUEST_EVENT, this.selectedRequestChanged)
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(SELECT_REQUEST_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
