<template>
  <div>
    <data-table :id='id' :headers='headers' :items='itemsToDisplay'>
      <template v-slot:item.leaveType="{ item }">
        {{ item.requestType | toLeaveTypeDescription }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.startDate | toShortDateString }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ item.endDate | toShortDateString }}
      </template>
      <template v-slot:item.resumptionDate="{ item }">
        {{ item.resumptionDate | toShortDateString }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status | toRequestStatusDescription }}
      </template>
      <template v-slot:item.actions="{ item }">
        <a class="action-btn mr-2 font-weight-bold" href="#" @click="selectRequest(item)">Select</a>
      </template>
    </data-table>
    <app-pager :totalNumberOfItems="numberOfItems" :numberOfItemsPerPage=numberOfItemsPerPage :numberOfPagers="numberOfPagers" :currentPage="currentPage" @paginate="goToPage" />
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { SELECT_REQUEST_EVENT } from '@/utils/constants'

  import { toLeaveTypeDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import DataTable from '@/components/ui/DataTable'
  import AppPager from '@/components/ui/AppPager'

  export default {
    name: 'loa-request-list',
    components: {
      DataTable,
      AppPager
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'requestList'
      },
      requests: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      headers: [
        {
          name: 'employeeName',
          title: 'employee name'
        },
        {
          name: 'leaveType',
          title: 'leave type'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'endDate',
          title: 'end date'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        },
        {
          name: 'duration',
          title: 'days'
        },
        {
          name: 'actions',
          title: ''
        }
      ],
      numberOfItemsPerPage: 10,
      numberOfPagers: 7,
      currentPage: 1
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      }),
      numberOfItems() {
        return this.requests.length
      },
      itemsToDisplay() {
        return this.requests.slice((this.currentPage - 1) * this.numberOfItemsPerPage, this.numberOfItemsPerPage * this.currentPage)
      }
    },
    filters: {
      toLeaveTypeDescription,
      toShortDateString
    },
    watch: {
      itemsToDisplay(value) {
        if (!value.length) {
          this.currentPage = 1
        }
      }
    },
    methods: {
      selectRequest(request) {
        eventBus.$emit(SELECT_REQUEST_EVENT, request)
      },
      goToPage(page) {
        this.currentPage = page
      }
    }
  }

</script>
