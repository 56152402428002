<template>
  <div id="leaveOfAbsenceRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit leave of absence' : isEditable ? 'leave of absence entry': 'view leave of absence entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedActionText">
          <div>
            <transition name="slide-fade">
              <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
            </transition>
            <form id="actionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isActioningRequest">
                <div class="form-group row mt-2">
                  <span class="font-weight-bold">comment</span>
                  <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                  <div class="invalid-feedback">
                    Please enter comments for this action.
                  </div>
                </div>
              </fieldset>
            </form>
          </div>          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired :isDisabled="disableFields" />
          <div>
            <employee-info-display :employee="employeeInfo" v-if="employeeInfo.employeeId"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="leaveRequestCanBeRaisedForEmployee || (!isEditable && request.id)">
      <div class="col">
        <form id="leaveEntryForm" enctype="multipart/form-data" onsubmit="event.preventDefault();" novalidate>
          <LeaveOfAbsenceRequestEntryForm ref="leaveEntryForm" :request="request" :employeeInfo="employeeInfo" :isFormDisabled="disableFields" :isEditable="isEditable" :sortedPreviousStudyOrLOA="sortedPreviousStudyOrLOA" :additionalData="additionalData" />
          <div class="m-2" v-if="request.id && isEditPage">
            <h4>review comment</h4>
            <textarea class="form-control col-md-5 offset-3" rows="4" v-model="request.comment" v-bind:disabled="disableFields" required></textarea>
          </div>
        </form>
        <div class="col-md-12 mt-4 text-center" v-if="isEditable">
          <button class="btn btn-danger d-inline mr-2" @click="resetData" v-bind:disabled="isSavingRequest">Cancel</button>
          <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
        </div>
        <div class="col-md-12 mt-4 text-center" v-else-if="isReviewPage">
          <button class="btn btn-secondary d-inline mr-2" :style="{visibility: canEditRequest(request) ? 'visible' : 'hidden'}" @click="editRequest(request)">Edit</button>
          <button class="btn btn-primary d-inline mr-2" v-if="canApproveRequest(request)" @click="approveRequest(request)" title="Approve request">Approve</button>
          <button class="btn btn-danger d-inline mr-2" v-if="canCancelRequest(request)" @click="cancelRequest(request)" title="Cancel request">Cancel</button>
          <button class="btn btn-danger d-inline mr-2" v-else-if="canApproveRequest(request)" @click="rejectRequest(request)" title="Reject request">Reject</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canApproveRequest(request)" @click="returnRequest(request)" title="Return request">Return</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { SCHEDULE_LEAVE_REQUEST, SAVE_EDITED_LEAVE_REQUEST, FETCH_CODETYPES, FETCH_PUBLICHOLIDAYS } from '@/store/action-type'

  import Enums from '@/utils/enums'
  import compareAsc from 'date-fns/compareAsc'

  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, studyLeaveRequestService, leaveOfAbsenceRequestService, leaveRequestService  } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'


  import EmployeeInfoDisplay from '@/components/loa/EmployeeInfoDisplay'
  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'
  import LeaveOfAbsenceRequestEntryForm from '@/components/leaveOfAbsence/EntryForm'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'
  import LOARequestMixin from '@/mixins/leaveRequest/loaRequest'

  export default {
    name: 'LeaveOfAbsenceRequestView',
    components: {
      EmployeeInfoDisplay,
      StartDateWarningModal,
      LeaveOfAbsenceRequestEntryForm
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin, LOARequestMixin],
    data: () => ({
      request: {
        reason: '',
        startDate: '',
        endDate: '',
        resumptionDate: '',
        duration: null,
        contactPhoneNumber: '',
        contactAddress: '',
        intendedApproverRoleRight: Enums.RoleRights.CanAdministerBenefits.value,
        intendedApproverId: null,
        intendedApproverName: null,
        remarks: '',
        comment: '',
        supportingDocuments: [],
        requestActions: [],
        requestType: Enums.RequestType.LeaveOfAbsence.value
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        locationName: '',
        workScheduleName: '',
        employeeTypeName: '',
        supervisorName: '',
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isOnForeignService: false,
        numberOfYearsOfContnousEmployment: 0
      },
      additionalData: {
        previousStudyOrLOA: []
      },
      approvalOrRejectionErrorMessage: ''
    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee)
        }
        return []
      },
      sortedPreviousStudyOrLOA() {
        return this.additionalData.previousStudyOrLOA.sort((a, b) => {
          const aResumptionDate = a.actualResumptionDate || a.resumptionDate
          const bResumptionDate = b.actualResumptionDate || b.resumptionDate

          return compareAsc(Date.parse(aResumptionDate), Date.parse(bResumptionDate))
        })
      },
      leaveRequestCanBeRaisedForEmployee() {
        // An employee has been selected
        if (this.isEditable && this.employeeInfo.employeeId) {
          // Only regular employees not on foreign service can raise a leave of absence request
          if (!this.employeeInfo.isRegularEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.InvalidEmployeeType, this.request.requestType)
            return false
          }
          if (this.appSettings.leaveOfAbsence.enforceNoApplicationFromEmployeeOnForeignService && this.employeeInfo.isOnForeignService) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.EmployeeOnForeignService, this.request.requestType)
            return false
          }
          if (this.appSettings.leaveOfAbsence.enforceMinimumNumberOfYearsAfterPreviousLOAsOrSLForRequest && this.sortedPreviousStudyOrLOA.length) {
            let sortedPreviousStudyOrLOA = [...this.sortedPreviousStudyOrLOA]
            if (this.$route.params.id) {
              sortedPreviousStudyOrLOA = sortedPreviousStudyOrLOA.filter((request) => request.id != this.$route.params.id)
            }
            if (sortedPreviousStudyOrLOA.length) {
              const lastSLorLOALeave = sortedPreviousStudyOrLOA[this.sortedPreviousStudyOrLOA.length - 1]
              if (!lastSLorLOALeave.actualResumptionDate) {
                this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RunningLeaveOfAbsenceOrStudyLeave, this.request.requestType)
                return false
              }
            }
          }
          if (this.appSettings.leaveOfAbsence.enforceRequiredYearsOfContinousEmployment && this.employeeInfo.numberOfYearsOfContnousEmployment < this.appSettings.leaveOfAbsence.requiredYearsOfContinousEmployment) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NumberOfYearsOfContinousEmployment, this.request.requestType)
            return false
          }
          if (this.request.id && !this.canEditRequest(this.request)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotEditRequest, this.request.requestType)
            return false
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      }
    },
    methods: {
      resetOtherData() {
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.isOnForeignService = false
        this.employeeInfo.numberOfYearsOfContnousEmployment = 0
        this.request.reason = ''
        this.request.contactPhoneNumber = ''
        this.request.contactAddress = ''
        this.request.intendedApproverRoleRight = Enums.RoleRights.CanAdministerBenefits.value
        this.request.supportingDocuments.splice(0, this.request.supportingDocuments.length)
        this.additionalData.previousStudyOrLOA.splice(0, this.additionalData.previousStudyOrLOA.length)
      },
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(employeeService.getEmployeeYearsOfContinousService(employee.employeeId))
        tasks.push(studyLeaveRequestService.getAllStudyLeaveRequestsForEmployee(employee.employeeId))
        tasks.push(leaveOfAbsenceRequestService.getAllLeaveOfAbsenceRequestsForEmployee(employee.employeeId))
        Promise.all(tasks).then((results) => {
          const employeeInfo = results[0].data
          const numberOfYearsOfContnousEmployment = results[1]
          const previousSLs = results[2]
          const previousLoas = results[3]
          self.setData(employeeInfo, numberOfYearsOfContnousEmployment, previousSLs, previousLoas)
        }).catch((error) => {
          console.log(error)
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      fetchExistingDataForView() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        self.resetData()
        leaveRequestService.getLeaveRequest(Enums.RequestType.LeaveOfAbsence.value, self.$route.params.id).then((result) => {
          const request = result
          const employee = request.employeeInfo
          employeeService.getEmployeeYearsOfContinousService(employee.employeeId).then((numberOfYearsOfContnousEmployment) => {
            self.setData(employee, numberOfYearsOfContnousEmployment, null, null, request)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.showErrorMessage(error)
        })
      },
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.LeaveOfAbsence.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          let tasks = []
          tasks.push(employeeService.getEmployeeYearsOfContinousService(employee.employeeId))
          tasks.push(studyLeaveRequestService.getAllStudyLeaveRequestsForEmployee(employee.employeeId))
          tasks.push(leaveOfAbsenceRequestService.getAllLeaveOfAbsenceRequestsForEmployee(employee.employeeId))
          Promise.all(tasks).then((results) => {
            const numberOfYearsOfContnousEmployment = results[0]
            const previousSLs = results[1]
            const previousLoas = results[2]
            self.setData(employee, numberOfYearsOfContnousEmployment, previousSLs, previousLoas, request)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.showErrorMessage(error)
        })
      },
      setData(employee, numberOfYearsOfContnousEmployment, previousSLs, previousLoas, request) {
        if (request) {
          this.setBaseRequestData(request)
          this.request.reason = request.reason
          this.request.contactPhoneNumber = request.contactPhoneNumber
          this.request.contactAddress = request.contactAddress
          this.request.intendedApproverRoleRight = request.intendedApproverRoleRight
          if (request.supportingDocuments.length) {
            for (const sd of request.supportingDocuments) {
              this.request.supportingDocuments.push(sd)
            }
          }
        }

        if (previousSLs && previousSLs.count > 0) {
          for (const sl of previousSLs.items) {
            this.additionalData.previousStudyOrLOA.push(sl)
          }
        }

        if (previousLoas && previousLoas.count > 0) {
          for (const loa of previousLoas.items) {
            this.additionalData.previousStudyOrLOA.push(loa)
          }
        }

        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.isOnForeignService = employee.isOnForeignService
        this.employeeInfo.numberOfYearsOfContnousEmployment = numberOfYearsOfContnousEmployment
      },
      submitRequest() {
        const self = this
        const requestForm = document.getElementById('leaveEntryForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            "input.form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
        }
        else {
          if ((this.$refs.leaveEntryForm.$refs.supportingDocumentsDropzone.getQueuedFiles().length + this.availableDocuments.length) < self.appSettings.leaveOfAbsence.minimumNumberOfSupportingDocuments) {
            self.showErrorMessage(`You must upload a minimum of ${self.appSettings.leaveOfAbsence.minimumNumberOfSupportingDocuments} supporting documents. Kindly upload a written application letter, admission letter and any other supporting document for this leave of absence request.`)
            return
          }
          self.request.files = []
          for (const file of this.$refs.leaveEntryForm.$refs.supportingDocumentsDropzone.getQueuedFiles()) {
            self.request.files.push({
              name: 'files',
              content: file
            })
          }
          self.closeMessage()
          self.isSavingRequest = true
          let operation = self.request.id ? SAVE_EDITED_LEAVE_REQUEST : SCHEDULE_LEAVE_REQUEST
          store.dispatch(operation, { request: self.request, employeeId: self.employeeInfo.employeeId })
            .then(() => {
              self.requestState = Enums.RequestState.Submitted
              self.showSuccessMessage('request submitted successfully')
              self.resetData()
              self.isCompleted = true
            })
            .catch((error) => {
              self.showErrorMessage(error)
            }).finally(() => {
              self.isSavingRequest = false
            })
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, '')
      const tasks = []
      tasks.push(store.dispatch(FETCH_CODETYPES))
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(next(vm => {
        vm.resetData()
        if (vm.isEditable && vm.$route.params.id) {
          vm.fetchExistingData()
        }
        else if (vm.$route.params.id) {
          vm.fetchExistingDataForView()
        }
        else {
          vm.pageIsFullyLoaded = true
          vm.isCompleted = false
          vm.fetchEmployeeData(vm.currentUser)
        }
      })).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }).finally(() => {
      })
    },
    mounted() {
      if (this.isEditable) {
        eventBus.$emit(Events.OpenInformationDialog, {
          isHtml: true,
          title: 'leave of absence policy',
          message: '<p>It is recommended you read the leave of absence policy before applying for one.</p><p class="text-danger font-weight-bold">Your request may be denied if it does not meet the requirements set out by the policy.</p><a target=”_blank” href="https://d2-hou-hr.dm.chevron.net/D2/servlet/DispatchDownload?_docbase=HR_PROD&_locale=en&event_name=d2_view&version=CURRENT&id=0902e981801c0b60">View policy</a>'
        })
      }
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
