<template>
  <div :id="id">
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">employee no.</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employeeNo }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">name</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.fullName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">employee type</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employeeTypeName }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">work schedule</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.workScheduleName }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-6 mr-0">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">work location</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.locationName }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-md-6 mr-0 text-left small text-secondary">scheduled days YTD</label>
          <span class="col-md-6 mr-0 text-left font-weight-bold" style="font-size:90%"><span v-bind:class="{ 'text-danger': numberOfDaysTaken >= totalDays }">{{ numberOfDaysTaken }}</span> of {{ totalDays }} days</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>

  export default {
    name: 'employee-info-display',
    props: {
       id: {
        id: String,
        required: false,
        default: 'employeeInfoDisplay'
      },
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      numberOfDaysTaken: {
        type: Number,
        required: true
      },
      totalDays: {
        type: Number,
        required: true
      }
    },
    computed: {
      totalNumberOfDays() {
        const duration = this.request.duration || 0
        return parseInt(duration) + this.numberOfDaysTaken
      }
    }
  }
</script>
