<script>
  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import { loaRequestService } from '@/services'
  export default {
    computed: {
      availableDocuments() {
        return this.request.supportingDocuments.filter((document) => !document.isDeleted)
      },
    },
    methods: {
      removeDocument(documentId) {
        //this.request.supportingDocuments = [...this.request.supportingDocuments.filter((document) => document.id !== documentId)]
        let index = null
        for (let i = 0; i < this.request.supportingDocuments.length; i++) {
          if (this.request.supportingDocuments[i].id === documentId) {
            this.request.supportingDocuments[i].isDeleted = true
            break
          }
        }
        //this.request.supportingDocuments.splice(index, 1)
      },
      downloadDocument(requestId, documentId, documentName) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'downloading document')
        loaRequestService.downloadDocument(requestId, documentId).then(function (data) {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', documentName)
          document.body.appendChild(link)
          link.click()
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        });
      }
    }
  }
</script>
