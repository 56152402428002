<template>
  <div id="vacationAllowanceRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>vacation allowance request entry</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <employee-info-display v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :requestCanBeRaisedForEmployee="vacationAllowanceRequestCanBeRaisedForEmployee" :additionalData="additionalData" :displayMode="!vacationAllowanceRequestCanBeRaisedForEmployee" :showVacationYearSelect="true"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="(vacationAllowanceRequestCanBeRaisedForEmployee && !showGenerateVacationButton && !showGenerateCashInLieuRequestButton)">
      <div class="col">
        <form id="vacationAllowanceEntryForm" onsubmit="event.preventDefault();" novalidate>
          <div>
            <div class="form-group row mt-4">
              <label class="col-md-6"><span class="font-weight-bold text-danger">*</span>allowance payment month</label>
              <select class="form-control col-md-5 mr-0" v-model="vacationMaster.vacationAllowanceRequest.payMonth" required v-bind:disabled="disableVacationAllowanceSelection || isSavingRequest">
                <option v-for="month in payMonths" :value="month.value">{{ month.description }}</option>
              </select>
              <!--<div class="invalid-feedback col-md-4">
                Please select a valid month for the paymnent of your vacation allowance.
              </div>-->
            </div>
          </div>
        </form>
        <div>
          <button class="btn btn-primary offset-6" v-if="!disableVacationAllowanceSelection" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
        </div>
      </div>
    </div>
    <div v-else-if="showGenerateVacationButton" class="d-flex justify-content-center">
      <button class="btn btn-primary d-inline mr-2" @click="generateVacationData" v-bind:disabled="isGeneratingVacationData">Generate {{ vacationMaster.vacationYear }} vacation data</button>
      <span v-if="showGenerateCashInLieuRequestButton">Or &nbsp;<router-link :to="{ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employeeInfo.employeeId || currentUser.employeeId }}">request cash in-lieu</router-link></span>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import { SCHEDULE_LEAVE_REQUEST } from '@/store/action-type'

  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, vacationMasterService, vacationAllowanceService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import EmployeeInfoDisplay from '@/components/vacationAllowance/EmployeeInfoDisplay'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import VacationMasterMixin from '@/mixins/leaveRequest/vacationMaster'

  export default {
    name: 'VacationAllowanceRequestView',
    components: {
      EmployeeInfoDisplay
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, VacationMasterMixin],
    data: () => ({
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        vacationAllowanceRequest: {
          id: null,
          payMonth: 12,
          paymentStatus: 0,
          allowanceType: 0,
          version: null
        },
        version: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isFieldEmployee: false,
        isShiftEmployee: false,
        isOnForeignService: false
      },
      additionalData: {
        vacationYears: [],
        startingUscheduledVacationDays: 0
      },
      requestType: Enums.RequestType.VacationAllowance.value,
      showGenerateVacationButton: false,
      showGenerateCashInLieuRequestButton: false,
      Enums: Enums
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee)
        }
        return []
      },
      disableVacationAllowanceSelection() {
        const today = new Date()
        if (this.vacationMaster.vacationYear > today.getFullYear()) {
          return false
        }
        const currentMonth = today.getMonth() + 1
        const currentDay = today.getDate()
        const payrollCutOff = currentMonth < Enums.Months.November.value ? this.appSettings.vacation.payrollCutOffDayPreNov : this.appSettings.vacation.payrollCutOffDayNovDec
        return (currentMonth == this.vacationMaster.vacationAllowanceRequest.payMonth && currentDay >= payrollCutOff && this.vacationMaster.vacationAllowanceRequest.id) || this.vacationMaster.vacationAllowanceRequest.payMonth < currentMonth
      },
      payMonths() {
        const self = this
        const today = new Date()
        if (this.vacationMaster.vacationYear > today.getFullYear()) {
          return Enums.Months
        }
        const currentMonth = today.getMonth() + 1
        const currentDay = today.getDate()
        const payrollCutOff = currentMonth < Enums.Months.November.value ? this.appSettings.vacation.payrollCutOffDayPreNov : this.appSettings.vacation.payrollCutOffDayNovDec
        return Object.keys(Enums.Months)
          .filter(function (key) {
            if (self.vacationMaster.vacationAllowanceRequest.payMonth == Enums.Months[key].value) {
              return true
            }
            if (Enums.Months[key].value == currentMonth) {
              return currentDay <= payrollCutOff
            }
            else {
              return Enums.Months[key].value > currentMonth
            }
          }).reduce((obj, key) => {
            obj[key] = Enums.Months[key];
            return obj;
          }, {});
      },
      vacationAllowanceRequestCanBeRaisedForEmployee() {
        // An employee has been selected
        if (this.employeeInfo.employeeId) {
          // Only regular field employees not on foreign service can raise a field vacation option scheduling request
          if (this.employeeInfo.isRegularEmployee) {
            //if (this.employeeInfo.isOnForeignService) {
            //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeBeingOnForeignService, this.requestType)
            //  return false
            //}
            if (!this.appSettings.vacation.allowPreAnniversaryScheduling && (new Date(this.employeeInfo.employmentDate)).getFullYear() === (new Date()).getFullYear()) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToPreAnniversary, this.requestType)
              return false
            }
            const today = new Date()
            if (this.vacationMaster.id && today.getFullYear() > this.vacationMaster.vacationYear) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.VacationCannotBeEditedForPreviousYears, this.requestType)
              return false
            }
            //if (this.vacationMaster.id && this.employeeInfo.workScheduleId == Enums.WorkSchedule.WS28x28.value) {
            //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.EmployeesOn28DaysScheduleCannotScheduleVacation, this.requestType)
            //  //this.errorMessageIfRequestCannotBeRaisedForEmployee = 'There was an error opening this page. The system administrators are currently working to fix this error. Kindly try again later'
            //  return false
            /*}*/
            if (this.showGenerateVacationButton) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.VacationMasterNotYetGenerated, this.requestType)
              return false
            }
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return true
          }
          else {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.requestType)
            return false
          }
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      }
    },
    watch: {
      'vacationMaster.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.fetchEmployeeData(this.employeeInfo)
          }
        }
      }
    },
    watch: {
      'vacationMaster.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.fetchEmployeeData(this.employeeInfo)
          }
        }
      }
    },
    methods: {
      resetData() {
        this.resetEmployeeData()
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.sbuStartDate = ''
        this.employeeInfo.payrollCountryId = ''
        this.employeeInfo.payrollCountry = ''
        this.employeeInfo.isShiftEmployee = false
        this.vacationMaster.id = null
        this.vacationMaster.vacationDaysDue = 0
        this.vacationMaster.travelDays = 0
        this.vacationMaster.vacationAndTravelDays = 0
        this.vacationMaster.carriedOverDays = 0
        this.vacationMaster.unScheduledVacationDays = 0
        this.vacationMaster.unscheduledRnRDays = 0
        this.vacationMaster.buyOutDays = 0
        this.vacationMaster.fieldVacationOption = ''
        this.vacationMaster.vacationAllowanceRequest.id = null
        this.vacationMaster.vacationAllowanceRequest.payMonth = 12
        this.vacationMaster.vacationAllowanceRequest.paymentStatus = 0
        this.vacationMaster.vacationAllowanceRequest.allowanceType = 0
        this.vacationMaster.vacationAllowanceRequest.version = null
        this.vacationMaster.version = null
        this.additionalData.startingUscheduledVacationDays = 0
        this.showGenerateVacationButton = false
        this.showGenerateCashInLieuRequestButton = false
      },
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(vacationMasterService.getVacationMasterFor(employee.employeeId, self.vacationMaster.vacationYear))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          var vacationMaster = results[1]
          self.setData(employee, vacationMaster)
        }).catch((error) => {
          console.log(error)
          self.handleError(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, vacationMaster) {
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        if (vacationMaster) {
          this.setVacationData(vacationMaster)
        }
        else {
          eventBus.$emit(Events.LongOperationCompleted)
          if (employee.workScheduleId != Enums.WorkSchedule.WS14x14.value) {
            this.showGenerateVacationButton = false
            this.showGenerateCashInLieuRequestButton = false
            if (employee.workScheduleId == Enums.WorkSchedule.WS28x28.value) {
              /*this.generateCashInLieuRequest()*/
              this.$router.push({ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employee.employeeId, vacationYear: this.vacationMaster.vacationYear } })
            }
            else {
              this.generateVacationData()
            }
          }
          else if (employee.isRegularEmployee && employee.workScheduleId == Enums.WorkSchedule.WS14x14.value) {
            this.showGenerateVacationButton = true
            this.showGenerateCashInLieuRequestButton = true
          }
        }
      },
      setVacationData(vacationMaster) {
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unScheduledVacationDays = this.additionalData.startingUscheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        this.vacationMaster.vacationYear = vacationMaster.vacationYear
        this.vacationMaster.version = vacationMaster.version

        this.setOtherVacationData(vacationMaster)

        this.showGenerateVacationButton = false
        this.showGenerateCashInLieuRequestButton = false

        eventBus.$emit(Events.LongOperationCompleted)
      },
      setOtherVacationData(vacationMaster) {
        if (!vacationMaster.vacationAllowanceRequest) {
          this.vacationMaster.vacationAllowanceRequest.payMonth = Enums.Months.December.value
        }
        else {
          this.vacationMaster.vacationAllowanceRequest.id = vacationMaster.vacationAllowanceRequest.id
          this.vacationMaster.vacationAllowanceRequest.payMonth = vacationMaster.vacationAllowanceRequest.payMonth
          this.vacationMaster.vacationAllowanceRequest.version = vacationMaster.vacationAllowanceRequest.version
        }
      },
      generateVacationData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'Generating vacation data')
        vacationMasterService.createVacationMasterFor({ employeeId: self.employeeInfo.employeeId, vacationYear: self.vacationMaster.vacationYear }).then((result) => {
          self.setVacationData(result)
        }).catch((error) => {
          self.handleError(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      submitRequest() {
        const self = this
        self.closeMessage()
        self.isSavingRequest = true
        vacationAllowanceService.scheduleVacationAllowance(self.employeeInfo.employeeId, self.vacationMaster.vacationYear, self.vacationMaster.vacationAllowanceRequest.payMonth).then(() => {
          self.showSuccessMessage('request submitted successfully')
          self.resetData()
          self.isCompleted = true
        })
          .catch((error) => {
            self.handleError(error)
          }).finally(() => {
            self.isSavingRequest = false
          })
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchEmployeeData(store.state.userModule.currentUser)
      })
    },
    mounted() {
      const currentYear = new Date().getFullYear()
      this.additionalData.vacationYears.push(currentYear)
      if (new Date().getMonth() >= this.appSettings.vacation.nextYearScheduleStartMonth - 1) {
        this.additionalData.vacationYears.push(currentYear + 1)
      }
      this.vacationMaster.vacationYear = currentYear
    }
  }
</script>
