<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false;setEndDate"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>reason for leave</label>
          <select class="form-control col-md-4" v-model="request.reason" required>
            <option value="" disabled>-- Select reason for compassionate leave --</option>
            <option v-for="compassionateLeaveReason in compassionateLeaveReasons" :value="compassionateLeaveReason.value">{{ compassionateLeaveReason.description }}</option>
          </select>
          <div class="invalid-feedback col-md-4">
            Please select a reason for this compassionate leave.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>start date</label>
          <input type="date" class="form-control col-md-2" id="startDate" v-model="request.startDate" :min="minimumStartDate" :max="maximumStartDate" required />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="invalid-feedback col-md-3">
            please select a valid date.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>number of days</label>
          <input type="number" class="form-control col-md-1" v-model="request.duration" min="1" :max="maximumDuration" v-bind:readonly="maximumDuration == 1" required />
          <div class="invalid-feedback  col-md-6">
            Please input a valid number <span class="font-weight-bold">(from 1 and {{ maximumDuration }})</span> for the duration of the compassionate leave.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">end date</label>
          <input type="date" class="form-control col-md-2" v-model="request.endDate" readonly required />
          <span> {{ request.endDate | toLongDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">resumption date</label>
          <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly required />
          <span> {{ request.resumptionDate | toLongDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>remarks</label>
          <textarea class="form-control col-md-5" rows="4" v-model="request.remarks" required></textarea>
          <div class="invalid-feedback  col-md-3">
            Please enter a valid remark.
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'
  import { toRequestStatusDescription } from '@/filters/enum'

  import isSameDay from 'date-fns/isSameDay'
  import { toLongDateString, fromServerDate, isLeaveStartDateLessThanCurrentDate } from '@/utils/date'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'

  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'

  export default {
    name: 'compassionate-entry',
    components: {
      StartDateWarningModal
    },
    mixins: [LeaveRequestEntryFormMixin],
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      additionalData: {
        type: Object,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      compassionateLeaveReasons: Enums.CompassionateLeaveReasons,
      showLeaveStartDateWarning: false,
      minimumStartDate: fromServerDate(new Date(new Date().getFullYear(), 0, 1)),
      maximumStartDate: fromServerDate(new Date(new Date().getFullYear(), 11, 31)),
      Enums: Enums
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings
      }),
      maximumDuration() {
        return Math.min(this.appSettings.compassionateLeave.maximumDurationForSingleRequest, this.appSettings.compassionateLeave.yearlyTotalDays - this.additionalData.numberOfDaysTaken)
      }
    },
    watch: {
      //maximumDuration: {
      //  immediate: true,
      //  handler(newValue, oldValue) {
      //    if (newValue == 1) {
      //      this.request.duration = newValue
      //    }
      //    else {
      //      this.request.duration = null
      //    }
      //  }
      //},
      'request.duration'() {
        this.setEndDate()
      }
    },
    filters: {
      toLongDateString,
      toRequestStatusDescription
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');

      startDateElement.addEventListener('change', (event) => {
        let selectedDate = new Date(event.target.value)
        var day = new Date(selectedDate).getUTCDay()
        let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
        if (!self.employeeInfo.isFieldEmployee && ([6, 0].includes(day) || startsOnHoliday)) {
          event.preventDefault();
          alert('Weekends / public holidays not allowed');
          event.target.value = '';
          self.request.startDate = null
          self.request.holidays = null
          self.request.endDate = null
          self.request.resumptionDate = null
          startDateElement.click()
          return
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      })
    }
  }
</script>
