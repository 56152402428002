<template>
  <div>
    <app-modal :static="true" :closeOnEscape="false" :show="show">
      <img src="../../assets/ajax-loader.gif" />
      &nbsp;Please Wait...
      <span class="d-block mt-4">
        <strong>{{ message }}</strong>
      </span>
    </app-modal>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>

  import AppModal from './AppModal'

  export default {
    name: 'app-spinner',
    components: {
      AppModal
    },
    props: {
      show: {
        type: Boolean,
        required: false,
        default: false
      },
      message: {
        type: String,
        required: false,
        default: 'loading data'
      }
    },
    methods: {

    }
  }
</script>
